import React from "react"
import accelerating_research from "images/researchers-page-accelerate-research.png"
import Panel1Image from "images/illus_icon_acce_research.png"
import Panel2Image from "images/illus_icon_data_mgmt.png"
import { DataManagementCreativeIllustration } from "components/library/illustrations/researchers-accelerate-research";
import { UseCasesBody, UseCasesBodyProps } from "../use-cases-body";

const accelResPoints: UseCasesBodyProps["panel1"]["points"] = [
	{
		title: "researchers-page.use-cases.panels.1.points.1.title",
		desc: "researchers-page.use-cases.panels.1.points.1.desc",
		image: accelerating_research,
	},
	{
		title: "researchers-page.use-cases.panels.1.points.2.title",
		desc: "researchers-page.use-cases.panels.1.points.2.desc",
		image: accelerating_research,
	},
	{
		title: "researchers-page.use-cases.panels.1.points.3.title",
		desc: "researchers-page.use-cases.panels.1.points.3.desc",
		image: accelerating_research,
	},
	{
		title: "researchers-page.use-cases.panels.1.points.4.title",
		desc: "researchers-page.use-cases.panels.1.points.4.desc",
		image: accelerating_research,
	},
]

const dataAnaPoints: UseCasesBodyProps["panel2"]["points"] = [
	{
		title: "researchers-page.use-cases.panels.2.points.1.title",
		desc: "researchers-page.use-cases.panels.2.points.1.desc",
		image: DataManagementCreativeIllustration,
	},
	{
		title: "researchers-page.use-cases.panels.2.points.2.title",
		desc: "researchers-page.use-cases.panels.2.points.2.desc",
		image: DataManagementCreativeIllustration,
	},
	{
		title: "researchers-page.use-cases.panels.2.points.3.title",
		desc: "researchers-page.use-cases.panels.2.points.3.desc",
		image: DataManagementCreativeIllustration,
	},
	{
		title: "researchers-page.use-cases.panels.2.points.4.title",
		desc: "researchers-page.use-cases.panels.2.points.4.desc",
		image: DataManagementCreativeIllustration,
	},
]

export const ResearchersUseCasesBody = () => {
	return (
		<UseCasesBody
			panel1={{
				title: "common.words.accelerate-research",
				description: "researchers-page.use-cases.panels.1.description",
				image: Panel1Image,
				points: accelResPoints,
				descriptioni18: {
					components: {
						anchor: <a className="anchor" />,
					},
				},
			}}
			panel2={{
				title: "common.words.data-management",
				description: "researchers-page.use-cases.panels.2.description",
				image: Panel2Image,
				points: dataAnaPoints,
			}}
		/>
	)
}
