import React from "react";
import { Container } from 'components/library'
import AcceleratedResearch from 'images/illus_design_experiments_data.png'
import OptimasationAtScale from 'images/illus_scale_high_quality.png'
import ScalableAutomation from 'images/illus_scale_efficiency.png'
import { graphql } from 'gatsby';
import { MiniNavbar } from "components/common/mini-navbar";
import { ResultsBlock } from "components/pages/solutions/results-block";
import { ResearchersHero } from "components/pages/solutions/researchers/hero/hero";
import { ResearchersHowItWorks } from "components/pages/solutions/researchers/how-it-works";
import { ResearchersUseCasesBody } from "components/pages/solutions/researchers/use-cases-body";
import { WhyPolymerize, WhyPolymerizeProps } from "components/pages/solutions/why-polymerize/why-polymerize";
import { UseCases } from "components/pages/solutions/use-cases";


const whyPolymerizeData: WhyPolymerizeProps['data'] = [
  {
    label: 'researchers-page.why-polymerize.reasons.1.label',
    heading: 'researchers-page.why-polymerize.reasons.1.heading',
    subheading: 'researchers-page.why-polymerize.reasons.1.subheading',
    image: AcceleratedResearch
  },
  {
    label: 'researchers-page.why-polymerize.reasons.2.label',
    heading: 'researchers-page.why-polymerize.reasons.2.heading',
    subheading: 'researchers-page.why-polymerize.reasons.2.subheading',
    image: OptimasationAtScale
  },
  {
    label: 'researchers-page.why-polymerize.reasons.3.label',
    heading: 'researchers-page.why-polymerize.reasons.3.heading',
    subheading: 'researchers-page.why-polymerize.reasons.3.subheading',
    image: ScalableAutomation
  },
]

const Researchers = () => {
  return (
    <main>
      <Container className="flex flex-col gap-28 py-16">
        <MiniNavbar
          className='hidden sm:flex'
          title='common.words.researchers'
          links={[
            { label: 'common.words.how-it-works', path: '#how' },
            { label: 'common.words.use-cases', path: '#use-cases' },
            { label: 'common.words.why-polymerize', path: '#why-polymerize' },
            { label: 'common.words.customers', path: '#customers' },
          ]}
        />

        <ResearchersHero />
        <ResearchersHowItWorks />

        <UseCases
          title="researchers-page.use-cases.title"
          subtitle="researchers-page.use-cases.subtitle"
        />
        <ResearchersUseCasesBody />

        <WhyPolymerize
          heading='researchers-page.why-polymerize.heading'
          subheading='researchers-page.why-polymerize.subheading'
          data={whyPolymerizeData}
        />
      </Container>

      <ResultsBlock />
    </main>
  );
};

export default Researchers;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 