import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowRight } from 'react-feather';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ResearchCycle } from "components/common/research-cycle/research-cycle";

export const ResearchersHowItWorks = () => {
    const { t } = useTranslation()

    return (
        <section id="how" className="text-center flex flex-col gap-8">
            <div className='flex flex-col items-center gap-8'>
                <StaticImage
                    alt="illustration"
                    src="../../../../images/icon_how_it_works.png"
                    className="w-20 sm:w-40"
                />
                <p className="tracking-wider text-gray-500 uppercase">
                    {t('common.words.how-it-works')}
                </p>
            </div>

            <div className='flex flex-col gap-4'>
                <p className="text-4xl tracking-tight text-gray-900 sm:text-5xl">
                    {t('researchers-page.how-it-works.heading')}
                </p>
                <p className="text-base sm:text-lg  md:text-xl">
                    {t('researchers-page.how-it-works.desc')}
                </p>
            </div>

            <span
                onClick={() => window.requestADemo()}
                className="text-theme-blue flex items-center gap-2 justify-center cursor-pointer"
            >
                <span>
                    {t('common.cta.demo')}
                </span>
                <ArrowRight size={20} />
            </span>

            <ResearchCycle />
        </section>
    )
};
