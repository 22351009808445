import React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type P = {
    label: TranslationKey
    desc: TranslationKey
    image: string
};

export type CardProps = P

export const Card = ({ desc, image, label }: P) => {
    return (
        <div className="rounded-lg w-[95%] h-full sm:w-full p-5 bg-b-100 flex flex-col gap-4">
            <img
                src={image}
                alt={label}
            />
            <p className="text-theme-blue text-lg uppercase">
                <Trans i18nKey={`${label}`} />
            </p>
            <p >
                <Trans i18nKey={`${desc}`} />
            </p>
        </div>
    )
};
